import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { Radar, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, Legend, Tooltip } from 'recharts';

// 自定义 tick 组件：根据雷达图中心与当前刻度的向量，沿该方向增加偏移距离
const CustomTick = (props) => {
  const { x, y, payload, index } = props;
  // 只显示每 10 个标签，减少混乱
  if (index % 10 !== 0) return null;
  
  const cx = 350;
  const cy = 350;
  
  const deltaX = x - cx;
  const deltaY = y - cy;
  const distance = Math.sqrt(deltaX * deltaX + deltaY * deltaY);
  
  // 定义希望额外增加的距离（例如 20 像素）
  const extraOffsetY = 19;
  const extraOffsetX = 23;

  const offsetX = distance === 0 ? 0 : (deltaX / distance) * extraOffsetX;
  const offsetY = distance === 0 ? 0 : (deltaY / distance) * extraOffsetY;
  
  const newX = x + offsetX;
  const newY = y + offsetY;
  
  return (
    <text x={newX} y={newY} dy={8} textAnchor="middle" fill="#000" fontSize={18}>
      {payload.value}
    </text>
  );
};

const CosineSimilarityChart = ({ show, handleClose, data1, data2, file1, file2 }) => {
  const data = data1.map((value, index) => ({
    dimension: `点 ${index + 1}`,
    [file1]: value.toFixed(4), // 保留4位小数
    [file2]: data2[index].toFixed(4),
  }));

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>香水鉴定可视化</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <RadarChart outerRadius={250} width={700} height={700} data={data}>
            <PolarGrid stroke="#666" />
            <PolarAngleAxis dataKey="dimension" tick={<CustomTick />} />
            <Radar name={file1} dataKey={file1} stroke="#8884d8" fill="#8884d8" fillOpacity={0.6} />
            <Radar name={file2} dataKey={file2} stroke="#82ca9d" fill="#82ca9d" fillOpacity={0.6} />
            <Legend />
            <Tooltip />
          </RadarChart>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CosineSimilarityChart;
